import React from "react"
import cuid from "cuid"
import { Row, Col } from "react-styled-flexboxgrid"

import Gallery from "react-photo-gallery"
import FlexContainer from "../FlexContainer"
import PricingDimensionCard from "../PricingDimensionCard"
import { Primary, Alternate } from "../Section"
import before1 from "../../images/snow_effects/before-1.jpg"
import after1 from "../../images/snow_effects/after-1.jpg"
import before2 from "../../images/snow_effects/before-2.jpg"
import after2 from "../../images/snow_effects/after-2.jpg"
import before3 from "../../images/snow_effects/before-3.jpg"
import after3 from "../../images/snow_effects/after-3.jpg"

const gallery = [
  {
    id: cuid(),
    src: before1,
    sizes: ["(min-width: 480px) 50vw", "(min-width: 1024px) 33.3vw", "100vw"],
    width: 2,
    height: 2,
    alt: "Alpharetta Lighting ",
  },
  {
    id: cuid(),
    src: after1,
    sizes: ["(min-width: 480px) 50vw", "(min-width: 1024px) 33.3vw", "100vw"],
    width: 4,
    height: 4,
    alt: "Columbia Snowfest ",
  },
  {
    id: cuid(),
    src: before2,
    sizes: ["(min-width: 480px) 50vw", "(min-width: 1024px) 33.3vw", "100vw"],
    width: 4,
    height: 4,
    alt: "Corrider ",
  },
  {
    id: cuid(),
    src: after2,
    sizes: ["(min-width: 480px) 50vw", "(min-width: 1024px) 33.3vw", "100vw"],
    width: 4,
    height: 4,
    alt: "Alpharetta Lighting ",
  },
  {
    id: cuid(),
    src: before3,
    sizes: ["(min-width: 480px) 50vw", "(min-width: 1024px) 33.3vw", "100vw"],
    width: 4,
    height: 4,
    alt: "Columbia Snowfest ",
  },
  {
    id: cuid(),
    src: after3,
    sizes: ["(min-width: 480px) 50vw", "(min-width: 1024px) 33.3vw", "100vw"],
    width: 4,
    height: 4,
    alt: "Corrider ",
  },
]

const SnowEffects = () => {
  return (
    <section>
      <Alternate centered backgroundHeight={350}>
        <h1>Snow as Special Effects</h1>
        <h3>Serving the Production Industry</h3>
      </Alternate>
      <Primary centered>
        <Row>
          <Col xs={12}>
            <p>
              We understand the critical nature of the Film, TV, and Commercial
              production business. And we know that when you rely on a
              specialist like SnowKings, you want the best. That is why we are
              on time, on budget, and always bring the value added and expertise
              you need when it comes to special effects and snow making.
            </p>
            <p>
              Our state of the art equipment, back up blowers, and over 30 years
              of experience in the snow and ice business make us your first
              choice. Please view About Us to learn more about our equipment and
              operations. And please let us know how we can help make your
              production a success.
            </p>
          </Col>
        </Row>
        <Row center="md">
          <Col xs={12} md={8}>
            <Gallery photos={gallery} />
          </Col>
        </Row>
      </Primary>
    </section>
  )
}

export default SnowEffects
