import React from "react"
import SnowEffects from "../components/SnowEffects"
import { Layout } from "../components/Layout"

const SnowEffectsPage = () => {
  return (
    <Layout>
      <SnowEffects />
    </Layout>
  )
}

export default SnowEffectsPage
